/**
 * Simple list used for outputting lists of text links
 */

import styled from '@emotion/styled';

import { PStyles } from '../type';
import { SPACE } from '../../constants';

const TextLinksList = styled.div`
  ul {
    li {
      list-style-type: disc;
    }
  }

  ul,
  ol {
    ${PStyles};
    margin-left: 0;
    padding-inline-start: 0;
    padding-left: ${SPACE.X2};
    margin-bottom: ${SPACE.X2};

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    li {
      list-style-position: outside;
      margin-bottom: ${SPACE.X1};
      padding-left: ${SPACE.X0};

      ul,
      ol {
        margin-bottom: ${SPACE.X1};
        margin-top: ${SPACE.X0};
        padding-left: ${SPACE.X1};
        padding-top: ${SPACE.X0};
      }
    }
  }
`;

export default TextLinksList;
